import {
  CloudBoardElement,
  CloudBoardTextElement,
  CloudBoardLinearElement,
  CloudBoardGenericElement,
} from "../element/types";
import { measureText } from "../utils";
import { randomInteger, randomId } from "../random";
import { newElementWith } from "./mutateElement";

type ElementConstructorOpts = {
  x: CloudBoardGenericElement["x"];
  y: CloudBoardGenericElement["y"];
  strokeColor: CloudBoardGenericElement["strokeColor"];
  backgroundColor: CloudBoardGenericElement["backgroundColor"];
  fillStyle: CloudBoardGenericElement["fillStyle"];
  strokeWidth: CloudBoardGenericElement["strokeWidth"];
  roughness: CloudBoardGenericElement["roughness"];
  opacity: CloudBoardGenericElement["opacity"];
  width?: CloudBoardGenericElement["width"];
  height?: CloudBoardGenericElement["height"];
  angle?: CloudBoardGenericElement["angle"];
};

function _newElementBase<T extends CloudBoardElement>(
  type: T["type"],
  {
    x,
    y,
    strokeColor,
    backgroundColor,
    fillStyle,
    strokeWidth,
    roughness,
    opacity,
    width = 0,
    height = 0,
    angle = 0,
    ...rest
  }: ElementConstructorOpts & Partial<CloudBoardGenericElement>,
) {
  return {
    id: rest.id || randomId(),
    type,
    x,
    y,
    width,
    height,
    angle,
    strokeColor,
    backgroundColor,
    fillStyle,
    strokeWidth,
    roughness,
    opacity,
    seed: rest.seed ?? randomInteger(),
    version: rest.version || 1,
    versionNonce: rest.versionNonce ?? 0,
    isDeleted: rest.isDeleted ?? false,
  };
}

export function newElement(
  opts: {
    type: CloudBoardGenericElement["type"];
  } & ElementConstructorOpts,
): CloudBoardGenericElement {
  return _newElementBase<CloudBoardGenericElement>(opts.type, opts);
}

export function newTextElement(
  opts: {
    text: string;
    font: string;
  } & ElementConstructorOpts,
): CloudBoardTextElement {
  const { text, font } = opts;
  const metrics = measureText(text, font);
  const textElement = newElementWith(
    {
      ..._newElementBase<CloudBoardTextElement>("text", opts),
      isDeleted: false,
      text: text,
      font: font,
      // Center the text
      x: opts.x - metrics.width / 2,
      y: opts.y - metrics.height / 2,
      width: metrics.width,
      height: metrics.height,
      baseline: metrics.baseline,
    },
    {},
  );

  return textElement;
}

export function newLinearElement(
  opts: {
    type: CloudBoardLinearElement["type"];
    lastCommittedPoint?: CloudBoardLinearElement["lastCommittedPoint"];
  } & ElementConstructorOpts,
): CloudBoardLinearElement {
  return {
    ..._newElementBase<CloudBoardLinearElement>(opts.type, opts),
    points: [],
    lastCommittedPoint: opts.lastCommittedPoint || null,
  };
}

// Simplified deep clone for the purpose of cloning CloudBoardElement only
//  (doesn't clone Date, RegExp, Map, Set, Typed arrays etc.)
//
// Adapted from https://github.com/lukeed/klona
function _duplicateElement(val: any, depth: number = 0) {
  if (val == null || typeof val !== "object") {
    return val;
  }

  if (Object.prototype.toString.call(val) === "[object Object]") {
    const tmp =
      typeof val.constructor === "function"
        ? Object.create(Object.getPrototypeOf(val))
        : {};
    for (const key in val) {
      if (val.hasOwnProperty(key)) {
        // don't copy top-level shape property, which we want to regenerate
        if (depth === 0 && (key === "shape" || key === "canvas")) {
          continue;
        }
        tmp[key] = _duplicateElement(val[key], depth + 1);
      }
    }
    return tmp;
  }

  if (Array.isArray(val)) {
    let k = val.length;
    const arr = new Array(k);
    while (k--) {
      arr[k] = _duplicateElement(val[k], depth + 1);
    }
    return arr;
  }

  return val;
}

export function duplicateElement<TElement extends Mutable<CloudBoardElement>>(
  element: TElement,
  overrides?: Partial<TElement>,
): TElement {
  let copy: TElement = _duplicateElement(element);
  copy.id = randomId();
  copy.seed = randomInteger();
  if (overrides) {
    copy = Object.assign(copy, overrides);
  }
  return copy;
}
